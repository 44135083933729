<template>
  <v-container fluid class="py-0">
    <v-toolbar flat height="56" class="toolbar" extended>
      <h2>Export Room Bookings</h2>
      <template #extension>
        <v-icon left>mdi-information</v-icon>
        <div class="d-flex flex-column">
          <div class="subtitle-2">
            Select rooms to include, the date range to query, then press prepare export.
          </div>
          <div class="subtitle-2">
            Once loaded, press Download Export, which will begin the export download.
          </div>
        </div>
      </template>
    </v-toolbar>

    <v-card class="content_wrapper" elevation="0">
      <v-card-text>
        <v-row>
          <v-col cols="5" class="pt-0">
            <v-subheader class="pl-4">Rooms to Include</v-subheader>
            <v-list dense class="list_wrapper">
              <div v-if="!loaded" class="loading">
                <v-progress-circular indeterminate color="accent"/>
              </div>
              <template v-else>
                <v-list-item-group v-model="selected" multiple class="selection-list" @change="newSelection">
                  <template v-for="(resources, siteId) in resourcesBySiteId">
                    <v-list-item :key="siteId" :value="siteId" @click="checkSite(siteId)">
                      <template #default="{ active }">
                        <v-list-item-content class="pa-0">
                          <v-list-item-title v-text="sitesById[siteId].title" class="font-weight-bold"/>
                        </v-list-item-content>

                        <v-list-item-action class="ma-0">
                          <v-checkbox :input-value="active" color="accent darken-1"/>
                        </v-list-item-action>
                      </template>
                    </v-list-item>

                    <v-list-item v-for="r in resources" :key="r.id" :value="r.id" @click="checkResource(siteId)">
                      <template #default="{ active }">
                        <v-list-item-content class="pa-0">
                          <v-list-item-title v-text="r.title"/>
                        </v-list-item-content>

                        <v-list-item-action class="ma-0">
                          <v-checkbox
                              :disabled="selected.includes(siteId)"
                              :input-value="active || selected.includes(siteId)"
                              color="accent darken-1"/>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                    <v-divider :key="siteId + 'divider'" class="mx-2"/>
                  </template>
                </v-list-item-group>
              </template>
            </v-list>
          </v-col>
          <v-col cols="4" class="pt-0">
            <v-subheader class="pl-0">Date Range</v-subheader>
            <div class="d-flex flex-column">
              <dl>
                <dt>From:</dt>
                <dd>
                  <v-datetime-field
                      dense
                      v-model="dateRange.start"
                      class="mt-0"
                      :with-time="false"
                      @input="newSelection"/>
                </dd>
                <dt>To:</dt>
                <dd>
                  <v-datetime-field
                      dense
                      v-model="dateRange.end"
                      class="mt-0"
                      :with-time="false"
                      @input="newSelection"/>
                </dd>
              </dl>
              <div v-if="!validDates || overMaxDays">
                <v-alert tile text class="ma-0 text-center" :type="validDates && !overMaxDays ? null : 'info'">
                  <span v-if="!validDates">Please select a valid date range</span>
                  <span v-else-if="overMaxDays">Please select {{ maxDays }} days (or fewer)</span>
                </v-alert>
              </div>
              <div class="d-flex">
                <v-spacer/>
                <v-btn
                    @click="prepareExport"
                    outlined
                    color="accent darken-1"
                    :loading="preparingInfo.loading"
                    :disabled="!validSelection">
                  <v-icon left>mdi-playlist-check</v-icon>
                  Prepare Export
                </v-btn>
              </div>
              <div class="d-flex mt-2">
                <v-spacer/>
                <v-btn
                    outlined
                    color="accent darken-1"
                    :href="downloadUrl"
                    :download="downloadFileName"
                    :disabled="!preparingInfo.prepared || bookings.length === 0">
                  <v-icon left>mdi-download</v-icon>
                  Download Export
                </v-btn>
              </div>
              <div class="d-flex mt-2" v-if="preparingInfo.prepared && bookings.length === 0">
                <v-spacer/>
                <span>No bookings found</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {Downloader} from '@/util/files';
import {mapActions, mapGetters, mapState} from 'vuex';
import VDatetimeField from '@/components/VDatetimeField';
import {addDays, startOfDay} from '@/util/dates';
import moment from 'moment';

const headers = [
  {id: 'link', title: 'Link'},
  {id: 'title', title: 'Subject'},
  {id: 'from', title: 'From (UTC)'},
  {id: 'to', title: 'To (UTC)'},
  {id: 'owner', title: 'Owner'},
  {id: 'createdVia', title: 'Created Via'},
  {id: 'site', title: 'Site'},
  {id: 'room', title: 'Room'},
  {id: 'checkIn', title: 'Check-In (UTC)'},
  {id: 'checkOut', title: 'Check-Out (UTC)'}
];

/**
 * @param {string} field
 * @return {string}
 */
function encodeCsvField(field) {
  const hasSpecialChar = /["\r\n,]/.test(field);
  if (!hasSpecialChar) return field;
  return `"${field.replace(/["]/g, '""')}"`;
}

/**
 * @param {firebase.firestore.Timestamp|undefined} ts
 * @return {string}
 */
function tsToDate(ts) {
  if (ts) {
    return ts.toDate().toISOString().replace('T', ' ').replace('Z', '');
  }
  return ts;
}

/**
 * @param {Object<string,*>} sitesById
 * @param {firebase.firestore.DocumentReference} resourceRef
 * @return {string}
 */
function siteName(sitesById, resourceRef) {
  const siteId = resourceRef && resourceRef.id.split('_')[0] || '<unknown>';
  const site = sitesById[siteId];
  return site && site.title || siteId;
}

export default {
  name: 'RoomBookingsExport',
  components: {VDatetimeField},
  data() {
    return {
      bookings: [],
      selected: [],
      dateRange: {
        start: startOfDay(addDays(new Date(), -7)),
        end: startOfDay()
      },
      downloader: new Downloader({type: 'data:text/csv'}),
      preparingInfo: {
        loading: false,
        prepared: false,
        text: 'Preparing export...',
        type: ''
      },
      maxDays: 31 // limit the maximum date range length selectable
    };
  },
  computed: {
    ...mapState('resources', ['resourcesBySiteId']),
    ...mapGetters('resources', ['loaded']),
    ...mapGetters('sites', ['sitesById', 'activeSiteId']),
    validDates() {
      return this.dateRange && this.dateRange.start && this.dateRange.end;
    },
    validSelection() {
      return this.validDates && !this.overMaxDays;
    },
    dateRangeDays() {
      if (!this.validDates) {
        return 0;
      }
      const start = moment(this.dateRange.start);
      const end = moment(this.dateRange.end);
      return end.diff(start, 'days');
    },
    overMaxDays() {
      return this.dateRangeDays > this.maxDays;
    },


    downloadFileName() {
      if (!this.validDates) {
        return `bookings.csv`;
      }
      const start = moment(this.dateRange.start);
      const end = moment(this.dateRange.end);
      return `bookings-${start.format('YYYY_MM_DD')}-${end.format('YYYY_MM_DD')}.csv`;
    },
    downloadUrl() {
      const content = this.fileContent;
      if (!content) {
        return null;
      }
      return this.downloader.createDataUrl(content);
    },
    fileContent() {
      const header = headers.map(h => h.title).join(',');
      const lines = this.bookings
          .map(booking => {
            const reservations = booking.get('reservations') || {};
            if (Object.keys(reservations).length > 1) {
              this.$logger.warn(`ignoring multiple reservations for ${booking.id}`);
            }
            const reservationKey = Object.keys(reservations)[0];
            const resourceRef = booking.get(`reservations.${reservationKey}.resource.ref`);
            const row = [
              `https://app.kahu.work/room-booking/calendar/booking/${booking.id}`,
              booking.get('title'),
              tsToDate(booking.get('bookedPeriod.fromTime')),
              tsToDate(booking.get('bookedPeriod.toTime')),
              booking.get('owner.title'),
              booking.get('createdVia.title'),
              siteName(this.sitesById, resourceRef),
              booking.get(`reservations.${reservationKey}.resource.title`) ||
                resourceRef && resourceRef.id || '<unknown>',
              tsToDate(booking.get(`reservations.${reservationKey}.checkInPeriod.fromTime`)),
              tsToDate(booking.get(`reservations.${reservationKey}.checkInPeriod.toTime`))
            ];
            return row.map(encodeCsvField).join(',');
          })
          .join('\r\n');
      return `${header}\r\n${lines}`;
    }
  },
  mounted() {
    this.load();
    this.selected = [this.activeSiteId];
  },
  methods: {
    ...mapActions('resources', ['fetchResourcesForAllSites']),
    ...mapActions('views/roomBooking/exportBookings', ['fetchBookings']),
    async prepareExport() {
      this.preparingInfo.loading = true;
      this.preparingInfo.prepared = false;
      try {
        const selection = {sites: [], resources: []};
        for (const id of this.selected) {
          if (this.sitesById.hasOwnProperty(id)) {
            selection.sites.push(id);
          } else {
            selection.resources.push(id);
          }
        }

        const end = moment(this.dateRange.end).endOf('day');
        this.bookings = await this.fetchBookings({
          selection,
          start: this.dateRange.start,
          end: end.toDate()
        });
        this.$nextTick(() => {
          this.preparingInfo.prepared = true;
          this.preparingInfo.loading = false;
        });
      } catch (e) {
        this.$logger.error('error preparing export', e);
      } finally {
        this.preparingInfo.loading = false;
      }
    },
    checkSite(siteId) {
      this.$nextTick(() => {
        if (this.selected.includes(siteId) && this.resourceIdsBySiteId) {
          // can we remove any child resources to simplify the list?
          const resourceIds = this.resourceIdsBySiteId[siteId];
          this.selected = this.selected.filter(id => !resourceIds.includes(id));
        }
      });
    },
    checkResource(siteId) {
      this.$nextTick(() => {
        if (!this.resourceIdsBySiteId) {
          return;
        }
        // can we remove any child resources to simplify the list?
        const resourceIds = this.resourceIdsBySiteId[siteId];
        const allForSite = resourceIds.every(id => this.selected.includes(id));
        if (allForSite) {
          this.selected = [
            ...this.selected.filter(id => !resourceIds.includes(id)),
            siteId
          ];
        }
      });
    },
    newSelection() {
      this.preparingInfo.prepared = false;
    },
    load() {
      this.fetchResourcesForAllSites()
          .catch(err => this.$logger.error('load', err));
    }
  }
};
</script>

<style scoped>
.list_wrapper {
  max-height: calc(90vh - 56px - 64px - 50px);
  overflow-y: auto;
}
dl {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 16px;
  padding: 16px 0;
}
.v-btn {
  min-width: 215px !important;
  justify-content: start;
}
</style>
