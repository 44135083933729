/**
 * Responsible for managing locally downloadable content.
 */
export class Downloader {
  /**
   * Create a new Downloader
   *
   * @param {Object} options
   * @param {string} [options.type]
   * @param {number} [options.max]
   */
  constructor({type = 'text/plain', max = 1}) {
    this.type = type;
    this.max = max;
    this._files = [];
  }

  /**
   * Create a data url that can be used to download the content.
   *
   * @param {string | Blob} content
   * @return {string}
   */
  createDataUrl(content) {
    if (typeof content === 'string') {
      content = new Blob([content], {type: this.type});
    }

    // avoid memory leaks
    while (this._files.length >= this.max) {
      window.URL.revokeObjectURL(this._files.shift());
    }

    const file = window.URL.createObjectURL(content);
    this._files.push(file);
    return file;
  }

  /**
   * Call this method when you're done with the Downloader. Clears all memory used by the system as part of this class.
   */
  dispose() {
    const files = this._files;
    this._files = [];
    files.forEach(window.URL.revokeObjectURL);
  }
}
